
import {
  Component,
  Vue
} from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import UploadVoucher from '../components/upload-voucher.vue'
import {
  apiGameAppPackageOrderDetail,
  apiGameAppPackageOrderRemarks
} from '@/api/order'
@Component({
  components: {
    LsDialog,
    MaterialSelect,
    UploadVoucher
  }
})
export default class OrderDetail extends Vue {
    // S Data
    showPay = false
    // 订单详情ID
    id: any = 0

    // 订单数据
    orderData: any = {

    }

    voucher = []

    // 商家备注
    remarks = ''

    // E Data

    // S Methods
    // 获取订单详情
    getOrderDetail () {
      apiGameAppPackageOrderDetail({
        set_meal_order_id: this.id
      }).then(res => {
        this.orderData = res
      })
    }

    // 商家备注
    postOrderRemarks () {
      apiGameAppPackageOrderRemarks({
        set_meal_order_id: this.id,
        remark: this.remarks
      }).then(res => {
        this.getOrderDetail()
      })
    }

    // E Methods

    created () {
      this.id = this.$route.query.id
      this.id && this.getOrderDetail()
    }
}
